/* eslint-disable max-len */

import * as assets from "./assets";

export const eventData = [
    {
        title: "BANG!",
        image: assets.skalka2024,
        previewImage: assets.previewSkalka2024,
        year: "2024",
        link: "https://photos.app.goo.gl/Qd1x4oPWkDGsZtGf7",
        isCamp: true,
    },
    {
        title: "Kobylí hlava",
        image: assets.sraz2024,
        previewImage: assets.previewSraz2024,
        year: "2024",
        link: "https://photos.app.goo.gl/F8km7e6WuVvHCxhG9",
    },
    {
        title: "Bláto-sraz",
        image: assets.sraz2023,
        previewImage: assets.previewSraz2023,
        year: "2023",
        link: "https://photos.app.goo.gl/PfvvSjwcVuBmuwUq9",
    },
    {
        title: "Zloděj blesku",
        image: assets.skalka2023,
        previewImage: assets.previewSkalka2023,
        year: "2023",
        link: "https://photos.app.goo.gl/KN64tex4kwYzghM39",
        isCamp: true,
    },
    {
        title: "Finanční krize",
        image: assets.sraz2022B,
        previewImage: assets.previewSraz2022B,
        year: "2022",
        link: "https://photos.app.goo.gl/fL5YWC4hhLke78fK7",
    },
    {
        title: "GAIA",
        image: assets.skalka2022,
        previewImage: assets.previewSkalka2022,
        year: "2022",
        link: "https://photos.app.goo.gl/CMFDPqkXyW3L6fhr6",
        isCamp: true,
    },
    {
        title: "Skalquid Game",
        image: assets.sraz2022,
        previewImage: assets.previewSraz2022,
        year: "2022",
        link: "https://photos.app.goo.gl/dHcJ1LzazJRNxshMA",
    },
    {
        title: "Pandemie",
        image: assets.sraz2021,
        previewImage: assets.previewSraz2021,
        year: "2021",
        link: "https://photos.app.goo.gl/NtjZi731q2Uj8hoK8",
    },
    {
        title: "Tropický svět",
        image: assets.skalka2021,
        previewImage: assets.previewSkalka2021,
        year: "2021",
        link: "https://photos.app.goo.gl/EAxnWYHf2VWjbBer7",
        isCamp: true,
    },
    {
        title: "Mafie",
        image: assets.skalka2020,
        previewImage: assets.previewSkalka2020,
        year: "2020",
        link: "https://photos.app.goo.gl/cDC1J1V3uMdQ6d2Y8",
        isCamp: true,
    },
    {
        title: "Pátek 13.",
        image: assets.sraz2019,
        previewImage: assets.previewSraz2019,
        year: "2019",
        link: "https://photos.app.goo.gl/D6anG2xjub3xFFCc9",
    },
    {
        title: "Malý princ",
        image: assets.skalka2019,
        previewImage: assets.previewSkalka2019,
        year: "2019",
        link: "https://photos.app.goo.gl/SkB35V17198Nmd1b8",
        isCamp: true,
    },
    {
        title: "Skalkan Horror Story",
        image: assets.sraz2018,
        previewImage: assets.previewSraz2018,
        year: "2018",
        link: "https://photos.app.goo.gl/9DC744iLj3ZKQyuK6",
    },
    {
        title: "Dračí jezdci",
        image: assets.skalka2018,
        previewImage: assets.previewSkalka2018,
        year: "2018",
        link: "https://photos.app.goo.gl/z81bXk3UcZFxDBTw6",
        isCamp: true,
    },
    {
        title: "Junktown",
        image: assets.sraz2017,
        previewImage: assets.previewSraz2017,
        year: "2017",
        link: "https://photos.app.goo.gl/sCkTWFdop3P8uh6i9",
    },
    {
        title: "Cesta do pravěku",
        image: assets.skalka2017,
        previewImage: assets.previewSkalka2017,
        year: "2017",
        link: "https://photos.app.goo.gl/6DwMbWP1182iLBei9",
        isCamp: true,
    },
    {
        title: "Blázinec",
        image: assets.sraz2016,
        previewImage: assets.previewSraz2016,
        year: "2016",
        link: "https://photos.app.goo.gl/U9XA1xnDDgiomnet6",
    },
    {
        title: "Účastníci zájezdu",
        image: assets.skalka2016,
        previewImage: assets.previewSkalka2016,
        year: "2016",
        link: "https://photos.app.goo.gl/tZ67G7dpxm2kFMes7",
        isCamp: true,
    },
    {
        title: "Pracovní tábor",
        image: assets.sraz2015,
        previewImage: assets.previewSraz2015,
        year: "2015",
        link: "https://photos.app.goo.gl/Bi6AdcKo343GKBBw7",
    },
    {
        title: "Léto s indiány",
        image: assets.skalka2015,
        previewImage: assets.previewSkalka2015,
        year: "2015",
        link: "https://photos.app.goo.gl/o9DvZCzgi3cmKPqS8",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.sraz2014,
        previewImage: assets.previewSraz2014,
        year: "2014",
        link: "https://photos.app.goo.gl/gYVb6bb5MJBkxYRw6",
    },
    {
        title: "Asterix a Obelix",
        image: assets.skalka2014,
        previewImage: assets.previewSkalka2014,
        year: "2014",
        link: "https://photos.app.goo.gl/ah3VLScZJSEU5GWXA",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.sraz2013,
        previewImage: assets.previewSraz2013,
        year: "2013",
        link: "https://photos.app.goo.gl/SDjqPtQaWxJR2o5LA",
    },
    {
        title: "Japonsko",
        image: assets.skalka2012,
        previewImage: assets.previewSkalka2012,
        year: "2012",
        link: "https://photos.app.goo.gl/Fdj1J4NfkvZPQpwVA",
        isCamp: true,
    },
    {
        title: "Harry Potter",
        image: assets.skalka2011,
        previewImage: assets.previewSkalka2011,
        year: "2011",
        link: "https://photos.app.goo.gl/Wnmdxmoycoo2un6U9",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.sraz2010,
        previewImage: assets.previewSraz2010,
        year: "2010",
        link: "https://photos.app.goo.gl/bUETLbLFxopXWsVc8",
    },
    {
        title: "Ostrov pokladů",
        image: assets.skalka2010,
        previewImage: assets.previewSkalka2010,
        year: "2010",
        link: "https://photos.app.goo.gl/YQM91FgyxnFXxTcSA",
        isCamp: true,
    },
    {
        title: "Pán času",
        image: assets.skalka2009,
        previewImage: assets.previewSkalka2009,
        year: "2009",
        link: "https://photos.app.goo.gl/hoAZuoFiyKpWbFTX7",
        isCamp: true,
    },
    {
        title: "Skalkawood",
        image: assets.skalka2008,
        previewImage: assets.previewSkalka2008,
        year: "2008",
        link: "https://photos.app.goo.gl/a9zZXAL3uskamfiv9",
        isCamp: true,
    },
    {
        title: "Egypt",
        image: assets.skalka2007,
        previewImage: assets.previewSkalka2007,
        year: "2007",
        link: "https://photos.app.goo.gl/bA2F6cAWb4x6yxG38",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka2005,
        previewImage: assets.previewSkalka2005,
        year: "2005",
        link: "https://photos.app.goo.gl/d2F38YQoLfm2Vmke7",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka2004,
        previewImage: assets.previewSkalka2004,
        year: "2004",
        link: "https://photos.app.goo.gl/pbPfaw3SrBuHArKY7",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka2001,
        previewImage: assets.previewSkalka2001,
        year: "2001",
        link: "https://photos.app.goo.gl/GZQGDRQRxg591ZmKA",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka2000,
        previewImage: assets.previewSkalka2000,
        year: "2000",
        link: "https://photos.app.goo.gl/pv3MLhH5NdALeekx5",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka1999,
        previewImage: assets.previewSkalka1999,
        year: "1999",
        link: "https://photos.app.goo.gl/Y8iYi21P48DERyYdA",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka1998,
        previewImage: assets.previewSkalka1998,
        year: "1998",
        link: "https://photos.app.goo.gl/sZcYauzS9iATTzJM8",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka1997,
        previewImage: assets.previewSkalka1997,
        year: "1997",
        link: "https://photos.app.goo.gl/vTksRtRoRqZX6fEb7",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka1996,
        previewImage: assets.previewSkalka1996,
        year: "1996",
        link: "https://photos.app.goo.gl/citG45KWHrs1JtnU6",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.skalka1994,
        previewImage: assets.previewSkalka1994,
        year: "1994",
        link: "https://photos.app.goo.gl/uBgRmXZLv2HkBqwMA",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.podkova1991,
        previewImage: assets.previewPodkova1991,
        year: "1991",
        link: "https://photos.app.goo.gl/KdbV4Dy3FhG21wMH6",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.podkova1990,
        previewImage: assets.previewPodkova1990,
        year: "1990",
        link: "https://photos.app.goo.gl/MxnLYUyS9HdzkouZ7",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.podkova1989,
        previewImage: assets.previewPodkova1989,
        year: "1989",
        link: "https://photos.app.goo.gl/ZEshsbXEjSxqh7iv6",
        isCamp: true,
    },
    {
        title: undefined,
        image: assets.rouchovany1976,
        previewImage: assets.previewRouchovany1976,
        year: "1976",
        link: "https://photos.app.goo.gl/uuFiZfXQFhdGCQPv9",
        isCamp: true,
    },
    {
        title: "Neznámé",
        image: assets.unknown,
        previewImage: assets.previewUnknown,
        year: "...",
        link: "https://photos.app.goo.gl/3y5cN8Ba4aMQ5MdQA",
    },
];
